import { styled } from "goober";

import Container from "@/shared/ContentContainer.jsx";

export const Layout = styled(Container)`
  .sections {
    display: grid;
    grid-auto-rows: auto;
    gap: var(--sp-12);

    @media screen and (max-width: 767px) {
      padding-block-start: 12rem;
    }

    @media screen and (max-width: 767px) {
      .⚡4b9250c1 .feature-image {
        top: 70%;
        max-width: 400px;
        width: 90%;
      }
    }
  }

  .augments-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: auto;
    gap: var(--sp-4);

    > * {
      background: var(--shade7);
      border-radius: var(--br);
    }
  }
  .augment-card {
    position: relative;
    padding: var(--sp-5);
    padding-block-start: var(--sp-6);
    height: 100%;
    overflow: hidden;

    > * {
      position: relative;
    }
  }
  .augment-img {
    margin-inline: auto;
    pointer-events: none;

    &.bg {
      position: absolute;
      top: 0;
      left: 50%;
      opacity: 0.025;
      translate: -50% -30%;
    }
  }
  .augment-name {
    text-align: center;
    margin-block: var(--sp-2);
  }
  .augment-description {
    color: var(--shade1);
  }

  scalelevel,
  attackspeed,
  scalebonus,
  status {
    text-decoration: underline;
  }
  spellname,
  abilityname,
  shield,
  movespeed {
    color: var(--shade0);
  }
  healing,
  health {
    color: #27cb0b;
  }
  scaleap {
    color: var(--ap);
  }
  scalead {
    color: var(--ad);
  }
`;
