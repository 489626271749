import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import { ArenaAugmentImage } from "@/game-lol/components/ArenaAugmentImage.jsx";
import { Layout } from "@/game-lol/components/ArenaAugmentList.style.jsx";
import { getPatchForArenaData } from "@/game-lol/utils/arena-queue-utils.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import Banner from "@/shared/SplashBanner.jsx";
import Tilting from "@/shared/Tilting.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const image = `${appURLs.CDN}/blitz/lol/arena/misc/arena-promo-hero-art.webp`;
const title = ["lol:arena.augmentsList", "Arena 2v2v2v2 Augments List"];
const title2 =
  "View all LoL Arena augments in the new 2v2v2v2 Arena mode. Also head over to the League Arena Augments page to check out the pick rate and tier list.";
const subTitle = ["common:games.lol.long", "League of Legends"];
const sections = [
  {
    rarity: 2,
    title: ["lol:arenaAugmentTiers.prismatic", "Prismatic"],
  },
  {
    rarity: 1,
    title: ["lol:arenaAugmentTiers.gold", "Gold"],
  },
  {
    rarity: 0,
    title: ["lol:arenaAugmentTiers.silver", "Silver"],
  },
];

function ArenaAugments() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const augments = getStaticData("augments", getPatchForArenaData());

  // Need to react this to static data being set
  void state.lol.staticData;

  const augmentGroups = Object.entries(augments)
    .map(([id, info]) => ({
      ...info,
      id,
    }))
    .reduce((acc, curr) => {
      if (!acc[curr.rarity]) acc[curr.rarity] = [];
      acc[curr.rarity].push(curr);
      return acc;
    }, {});

  return (
    <Layout>
      <Banner
        image={image}
        title={t(...title)}
        title2={title2}
        subtitle={t(...subTitle)}
      />
      <div className="sections">
        {sections.map((section) => (
          <section
            key={section.rarity}
            open={augmentGroups[section.rarity]?.length}
          >
            {/* <h4 className="type-h6">{t(...section.title)}</h4> */}
            <div className="augments-group">
              {(augmentGroups[section.rarity] || []).map((augment, i) => (
                <Augment
                  key={augment.id}
                  id={augment.id}
                  name={augment.displayName}
                  description={augment.description}
                  lazy={i > 15}
                />
              ))}
            </div>
          </section>
        ))}
      </div>
    </Layout>
  );
}

function Augment({ lazy, id, name, description }) {
  return (
    <Tilting>
      <div className="augment-card">
        <ArenaAugmentImage
          noTooltip
          lazy={lazy}
          augmentId={id}
          width={240}
          height={240}
          className="bg"
        />
        <ArenaAugmentImage
          noTooltip
          lazy={lazy}
          augmentId={id}
          width={68}
          height={68}
        />
        <h4 className="augment-name type-article-headline">{name}</h4>
        <p
          className="augment-description type-caption"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Tilting>
  );
}

export function meta() {
  const title = [
    "lol:meta.arenaAugmentsInfo.title",
    "LoL Arena 2v2v2v2 Augments List",
  ];
  const description = [
    "lol:meta.arenaAugmentsInfo.description",
    "All LoL Arena augments list for the 2v2v2v2 League mode.",
  ];

  return {
    title,
    description,
  };
}

export default ArenaAugments;
